<a id="skip-to-main-link" *ngIf="!this.isMobile" class="skip-to-main" (keydown)="skipToContent($event)" tabindex="0">Saltar al contenido principal</a>
<a id="skip-to-faq-link" *ngIf="!this.isMobile && isHome" (keydown)="frequentQuestion($event)" class="skip-to-main" tabindex="0">Saltar a preguntas frecuentes</a>
<div class="wrapper">
	<imv-ui-header [home]="isHome || isMaintenance" [link]="imageLink" *ngIf="!isMaintenance">
		<div *ngIf="false" slot="languages">
			<imv-ui-input
				class="imv-form__field"
				[disabled]="false"
				[value]="value"
				[type]="'select'"
				[options]="languages"
				[label]="'Idiomas'"
				(click)="changeLangs($event)"
			></imv-ui-input>
		</div>
	</imv-ui-header>

	<main class="main">
		<div [ngClass]="{ 'imv-wrapper': !isHome }">
			<imv-ui-breadcrumbs
				*ngIf="isBreadcrumb || isError404 || isError500 || isProceedings || isConsultComponent"
				[items]="breadcrumbsItems"
				(selected)="handleHomeNavigationRequest($event)"
				ariaLabel="nav"
			></imv-ui-breadcrumbs>
			<div class="main__container">
				<aside class="main__container__stepper" *ngIf="isStepper">
					<imv-steps></imv-steps>
				</aside>
				<section
					[ngClass]="{
						'main__container__content': !isHome,
						'imv-error': isError404 || isError500 || isMaintenance,
						'imv-proceedings': isProceedings,
						'imv-consult-component': isConsultComponent
					}"
					id="content-main"
					tabindex="-1"
				>
					<router-outlet></router-outlet>
				</section>
			</div>
		</div>
		<imv-navigation class="navigation-bar" #imvNavigation *ngIf="isNavigation" (openModal)="handleHomeNavigationRequest()"> </imv-navigation>
	</main>
</div>
<imv-ui-modal #modalCancel [isMobile]="isMobile" type="cancel" class="modal-align-title">
	<div slot="title">
		<h1 id="modalCancelTitle" *ngIf="!isProceedings">{{ 'cancel-request' | translate }}</h1>
		<h2 slot="title" id="modalCancelTitle" *ngIf="isProceedings">{{ 'exit' | translate }}</h2>
	</div>
	<p slot="content" id="modalCancelContent" *ngIf="isFormStep" [innerHTML]="'form-step-exit-message' | translate"></p>
	<p slot="content" id="modalCancelContent" *ngIf="!isFormStep && !isProceedings">{{ 'questionnaire-exit-message' |
		translate }}</p>
	<p slot="content" id="modalCancelContent" *ngIf="isProceedings">{{ (isCaiss ? 'proceedings-exit-message-caiss' :
		'proceedings-exit-message') | translate }}</p>
	<div slot="actions" class="two-buttons">
		<imv-ui-button eid="close-cancel-modal-button" theme="secondary" variant=" mdc-button--secondary"
			[fullWidth]="isMobile" class="mobile-full-width" (click)="closeCancelModal()">
			<span slot="text">{{ 'continue' | translate }}</span>
		</imv-ui-button>
		<imv-ui-button eid="confirm-cancel-modal-button" theme="primary"
			[variant]="isMobile ? 'mdc-button--primary mdc-button--no-margin': 'mdc-button--primary'"
			[fullWidth]="isMobile" class="mobile-full-width" (click)="onConfirmCancel()">
			<span slot="text" *ngIf="!isProceedings">{{ 'cancel' | translate }}</span>
			<span slot="text" *ngIf="isProceedings">{{ 'yes-exit' | translate }}</span>
		</imv-ui-button>
	</div>
</imv-ui-modal>

<imv-ui-modal #modalValidationError [isMobile]="isMobile" type="fail" (closeModal)="closeErrorModal()">
	<h2 slot="title" id="modalValidationTitle" *ngIf="validationErrorServiceDown">Error del servicio</h2>
	<p slot="text" id="modalValidationContent" *ngIf="validationErrorServiceDown">El servicio de validación de pasos no funciona actualmente, prueba más tarde.</p>
	<h2 slot="title" id="modalValidationTitle" *ngIf="!validationErrorServiceDown">{{ 'validationErrorTitle' | translate }}</h2>
	<p slot="text" id="modalValidationContent" *ngIf="!validationErrorServiceDown">{{ validationErrorDescription }}</p>
</imv-ui-modal>

<imv-ui-modal #modalGeneralError (closeModal)="closeErrorModal()" [isMobile]="isMobile" type="fail" class="modal-align-title">
	<div slot="title">
		<h2 id="modalGeneralErrorTitle">Error</h2>
	</div>
	<p slot="text" id="modalGeneralErrorContent">{{ generalErrorMessage }}</p>
</imv-ui-modal>
<imv-ui-modal #modalLoading [isMobile]="isMobile" type="loading" [persist]="true">
	<h1 slot="title" id="modalLoadingTitle">{{ 'loadingTitle' | translate }}</h1>
	<p slot="content" id="modalLoadingContent">{{ 'loadingText' | translate }}</p>
</imv-ui-modal>
<imv-ui-modal #modalDraftVersion [isMobile]="isMobile" type="fail">
	<h1 slot="title" id="modalDraftVersionTitle">{{ 'draftVersionTitle' | translate }}</h1>
	<p slot="text" id="modalDraftVersionContent">{{ 'draftVersionText' | translate }}</p>
	<div slot="actions">
		<imv-ui-button eid="close-version-modal-button" theme="secondary" variant=" mdc-button--secondary" (click)="onConfirmCancel()">
			<span slot="text">{{ 'draftVersionButtonKo' | translate }}</span>
		</imv-ui-button>
		<imv-ui-button eid="confirm-version-modal-button" theme="primary" variant=" mdc-button--primary" (click)="onConfirmCancel(true)">
			<span slot="text">{{ 'draftVersionButtonOk' | translate }}</span>
		</imv-ui-button>
	</div>
</imv-ui-modal>

<imv-ui-modal #modalNewVersion [isMobile]="isMobile" type="success" [persist]="true">
	<h1 slot="title" id="modalNewVersionTitle">{{ 'newVersionTitle' | translate }}</h1>
	<p slot="text" id="modalNewVersionContent">{{ 'newVersionText' | translate }}</p>
	<div slot="actions">
		<imv-ui-button eid="new-app-version-modal-button" theme="primary" (click)="reloadPage()">
			<span slot="text">{{ 'update' | translate }}</span>
		</imv-ui-button>
	</div>
</imv-ui-modal>
<imv-dialogs [i18n]="i18nDialogs"></imv-dialogs>
<section class="footer-sticky-elements">
	<tenant-navigation #imvNavigation *ngIf="isNavigation" (openModal)="handleHomeNavigationRequest()"> </tenant-navigation>
	<imv-ui-cookies-banner #cookiesBanner>
		<span slot="title">{{ 'cookies-banner.title' | translate }}</span>
		<span slot="text">{{ 'cookies-banner.text' | translate }}</span>
		<a
			slot="link"
			[href]="'cookies-banner.link.href' | translate"
			[title]="'cookies-banner.link.title' | translate"
			[attr.aria-label]="'cookies-banner.link.title' | translate"
			target="_blank"
			role="link"
		>
			{{ 'cookies-banner.link.text' | translate }}
		</a>
		<div slot="accept-button">
			<imv-ui-button eid="cookies-banner-accept-cookies-button" theme="primary" variant="cookies" (click)="acceptCookiesBanner()">
				<span slot="text">{{ 'cookies-banner.acceptButtonText' | translate }}</span>
			</imv-ui-button>
		</div>
		<div slot="reject-button">
			<imv-ui-button eid="cookies-banner-reject-cookies-button" theme="primary" variant="cookies" (click)="rejectCookiesBanner()">
				<span slot="text">{{ 'cookies-banner.rejectButtonText' | translate }}</span>
			</imv-ui-button>
		</div>
	</imv-ui-cookies-banner>
</section>
