<!-- <imv-ui-jumbotron id="content">
	<span slot="header">{{ 'title-banner' | translate }}</span>
	<span slot="body">{{ 'desc-banner' | translate }}</span>
	<div slot="footer">
		<imv-ui-button eid="init-banner-button" [disabled]="disableInitButton" theme="primary" fullWidth="true" (click)="navigateQuestionnaire()">
			<span slot="text">{{ 'button-banner' | translate }}</span>
		</imv-ui-button>
	</div>
</imv-ui-jumbotron> -->

<div id="content" [ngClass]="{ 'banner': !isMobile, 'banner_mobile': isMobile }">
	<div [ngClass]="{ 'content-text': !isMobile, 'content-text_mobile': isMobile }">
		<span slot="header" class="header_mobile">{{ 'title-banner' | translate }}</span>
		<span slot="body" class="body_mobile">{{ 'desc-banner' | translate }}</span>
		<div slot="footer" class="footer_mobile">
			<imv-ui-button eid="init-banner-button" [disabled]="disableInitButton" theme="primary" fullWidth="true" (click)="navigateQuestionnaire()">
				<span slot="text">{{ 'button-banner' | translate }}</span>
			</imv-ui-button>
		</div>
	</div>
</div>

<div class="imv-wrapper">
	<p>{{ 'portal-info' | translate }}</p>
	<div class="imv-wrapper__box-link">
		<imv-ui-box-link
			eid="recover-draft-link"
			class="imv-wrapper__box-link--left"
			draft="true"
			(keyup.enter)="navigateRequest()"
			(keyup.space)="navigateRequest()"
			(click)="navigateRequest()"
		>
			<span slot="text">{{ 'draft-boxlink' | translate }}</span>
		</imv-ui-box-link>
		<imv-ui-box-link
			eid="recover-expedient-link"
			class="imv-wrapper__box-link--right"
			draft="false"
			(keyup.enter)="navigateConsult()"
			(keyup.space)="navigateConsult()"
			(click)="navigateConsult()"
		>
			<span slot="text">{{ 'status-boxlink' | translate }}</span>
		</imv-ui-box-link>
	</div>
	<p>{{ 'info-variaciones' | translate }}</p>
	<div class="imv-wrapper__box-link">
		<imv-ui-box-link
			eid="other-variations-link"
			class="imv-wrapper__box-link--right"
			extraIcon="./assets/pwa/form/home/variaciones.png"
			(keyup.enter)="navigateVariations()"
			(keyup.space)="navigateVariations()"
			(click)="navigateVariations()"
		>
			<span slot="text">{{ 'variations-boxlink' | translate }}</span>
		</imv-ui-box-link>
	</div>
	<p>{{ 'info-simulacion' | translate }}</p>
	<div class="imv-wrapper__box-link">
		<a id="other-benefits-link" target="_blank" [href]="employeeSimulationSafeUrl">
			<imv-ui-box-link eid="recover-benefits-link" class="imv-wrapper__box-link--right" extraIcon="./assets/pwa/form/home/incentivo.png">
				<span slot="text">{{ 'simulator-boxlink' | translate }}</span>
			</imv-ui-box-link>
		</a>
	</div>

	<div *ngIf="appConfig.caiss === 0">
		<h1 class="imv-wrapper__title imv-wrapper__title--main">{{ 'imv-title' | translate }}</h1>
		<p class="imv-wrapper__text">
			{{ 'imv-text-1' | translate }}
		</p>
		<p class="imv-wrapper__text">
			{{ 'imv-text-2' | translate }}
		</p>
		<h2 class="imv-wrapper__title">{{ 'procedure-title' | translate }}</h2>
		<p class="imv-wrapper__text">{{ 'procedure-text' | translate }}</p>
		<section class="imv-wrapper__frequentQuestion" id="frequentQuestion" tabindex="-1" itemscope itemtype="https://schema.org/FAQPage">
			<h2 class="imv-wrapper__title">{{ 'questions-title' | translate }}</h2>
			<article>
				<imv-ui-panel eid="faq-1" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
					<p slot="title" itemprop="name">{{ 'panel1-title' | translate }}</p>
					<div slot="bodyContent" class="imv-wrapper__panel-content" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
						<div itemprop="text">
							<p>{{ 'panel1-text-1' | translate }}</p>
							<ul>
								<li>
									<p>{{ 'panel1-li-1' | translate }}</p>
								</li>
								<li>
									<p>{{ 'panel1-li-2' | translate }}</p>
								</li>
								<li>
									<p>{{ 'panel1-li-3' | translate }}</p>
								</li>
							</ul>
							<p>{{ 'panel1-text-2' | translate }}</p>
						</div>
					</div>
				</imv-ui-panel>
			</article>
			<article>
				<imv-ui-panel eid="faq-2" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
					<p slot="title" itemprop="name">{{ 'panel2-title' | translate }}</p>
					<div slot="bodyContent" class="imv-wrapper__panel-content" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
						<div itemprop="text">
							<p>{{ 'panel2-text-1' | translate }}</p>
							<p>{{ 'panel2-text-2' | translate }}</p>
							<ul>
								<li>
									<p>{{ 'panel2-li-1' | translate }}</p>
								</li>
								<li>
									<p>{{ 'panel2-li-2' | translate }}</p>
								</li>
								<li>
									<p>{{ 'panel2-li-3' | translate }}</p>
								</li>
							</ul>
							<p>{{ 'panel2-text-3' | translate }}</p>
						</div>
					</div>
				</imv-ui-panel>
			</article>
			<article>
				<imv-ui-panel eid="faq-3" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
					<p slot="title" itemprop="name">{{ 'panel3-title' | translate }}</p>
					<div slot="bodyContent" class="imv-wrapper__panel-content" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
						<div itemprop="text">
							<p>{{ 'panel3-text-1' | translate }}</p>
							<ul>
								<li>
									<p>{{ 'panel3-li-1' | translate }}</p>
								</li>
								<li>
									<p>{{ 'panel3-li-2' | translate }}</p>
								</li>
								<li>
									<p>{{ 'panel3-li-3' | translate }}</p>
								</li>
							</ul>
							<p>{{ 'panel3-text-2' | translate }}</p>
							<ul>
								<li>
									<p>{{ 'panel3-li-4' | translate }}</p>
								</li>
								<li>
									<p>{{ 'panel3-li-5' | translate }}</p>
								</li>
								<li>
									<p>{{ 'panel3-li-6' | translate }}</p>
									<ul>
										<li>
											<p>{{ 'panel3-li-7' | translate }}</p>
										</li>
										<li>
											<p>{{ 'panel3-li-8' | translate }}</p>
										</li>
									</ul>
								</li>
							</ul>
						</div>
					</div>
				</imv-ui-panel>
			</article>
			<article>
				<imv-ui-panel eid="faq-4" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
					<p slot="title" itemprop="name">{{ 'panel4-title' | translate }}</p>
					<div slot="bodyContent" class="imv-wrapper__panel-content" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
						<div itemprop="text">
							<p>{{ 'panel4-text-1' | translate }}</p>
							<p>{{ 'panel4-text-2' | translate }}</p>
							<p>{{ 'panel4-text-3' | translate }}</p>
							<p>{{ 'panel4-text-4' | translate }}</p>
						</div>
					</div>
				</imv-ui-panel>
			</article>
			<article>
				<imv-ui-panel eid="faq-5" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
					<p slot="title" itemprop="name">{{ 'panel5-title' | translate }}</p>
					<div slot="bodyContent" class="imv-wrapper__panel-content" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
						<div itemprop="text">
							<p>{{ 'panel5-text-1' | translate }}</p>
							<p>{{ 'panel5-text-2' | translate }}</p>
						</div>
					</div>
				</imv-ui-panel>
			</article>
			<article>
				<imv-ui-panel eid="faq-6" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
					<p slot="title" itemprop="name">{{ 'panel6-title' | translate }}</p>
					<div slot="bodyContent" class="imv-wrapper__panel-content" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
						<div itemprop="text">
							<p>{{ 'panel6-text-1' | translate }}</p>
							<p>{{ 'panel6-text-2' | translate }}</p>
							<p>{{ 'panel6-text-3' | translate }}</p>
						</div>
					</div>
				</imv-ui-panel>
			</article>
			<article>
				<imv-ui-panel eid="faq-7" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
					<p slot="title" itemprop="name">{{ 'panel7-title' | translate }}</p>
					<div slot="bodyContent" class="imv-wrapper__panel-content" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
						<div itemprop="text">
							<p>{{ 'panel7-text-1' | translate }}</p>
							<p>{{ 'panel7-text-2' | translate }}</p>
							<p>{{ 'panel7-text-3' | translate }}</p>
						</div>
					</div>
				</imv-ui-panel>
			</article>
			<article>
				<imv-ui-panel eid="faq-8" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
					<p slot="title" itemprop="name">{{ 'panel8-title' | translate }}</p>
					<div slot="bodyContent" class="imv-wrapper__panel-content" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
						<div itemprop="text">
							<p>{{ 'panel8-text-1' | translate }}</p>
							<p>{{ 'panel8-text-2' | translate }}</p>
						</div>
					</div>
				</imv-ui-panel>
			</article>
			<article>
				<imv-ui-panel eid="faq-9" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
					<p slot="title" itemprop="name">{{ 'panel9-title' | translate }}</p>
					<div slot="bodyContent" class="imv-wrapper__panel-content" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
						<div itemprop="text">
							<p>{{ 'panel9-text-1' | translate }}</p>
							<ul>
								<li>
									<p innerHtml="{{ 'panel9-text-2' | translate }}"></p>
								</li>
								<li>
									<p innerHtml="{{ 'panel9-text-3' | translate }}"></p>
								</li>
								<li>
									<p innerHtml="{{ 'panel9-text-4' | translate }}"></p>
									<ul>
										<li>
											<p>{{ 'panel9-li-1' | translate }}</p>
										</li>
										<li>
											<p>{{ 'panel9-li-2' | translate }}</p>
										</li>
										<li>
											<p>{{ 'panel9-li-3' | translate }}</p>
										</li>
										<li>
											<p>{{ 'panel9-li-4' | translate }}</p>
										</li>
										<li>
											<p>{{ 'panel9-li-5' | translate }}</p>
										</li>
										<li>
											<p>{{ 'panel9-li-6' | translate }}</p>
										</li>
									</ul>
								</li>
								<li>
									<p innerHtml="{{ 'panel9-text-5' | translate }}"></p>
								</li>
								<li>
									<p innerHtml="{{ 'panel9-text-6' | translate }}"></p>
								</li>
							</ul>
						</div>
					</div>
				</imv-ui-panel>
			</article>
			<article>
				<imv-ui-panel eid="faq-10" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
					<p slot="title" itemprop="name">{{ 'panel10-title' | translate }}</p>
					<div slot="bodyContent" class="imv-wrapper__panel-content" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
						<div itemprop="text">
							<p>{{ 'panel10-text-1' | translate }}</p>
						</div>
					</div>
				</imv-ui-panel>
			</article>
		</section>
	</div>
</div>
<footer class="imv-footer">
	<ng-container *ngIf="appConfig.caiss === 0">
		<article class="imv-footer__item">
			<p class="imv-footer__title">{{ 'footerL-title' | translate }}</p>
			<p class="imv-footer__desc">{{ 'footerL-desc' | translate }}</p>
			<a id="other-benefits-link" class="imv-footer__link" href="{{ moreInfoLink }}" title="Acceder a prestaciones"
				>{{ 'footerL-link' | translate
				}}<img class="imv-footer__icon imv-footer__icon--arrow" src="./assets/pwa/home/atoms-icons-24-ico-chevron-right.png" alt="Siguiente" role="presentation"
			/></a>
		</article>
		<article class="imv-footer__item imv-footer__item--alternative">
			<p class="imv-footer__title">{{ 'footerR-title' | translate }}</p>
			<p class="imv-footer__desc">{{ 'footerR-desc' | translate }}</p>
			<span
				><img class="imv-footer__icon imv-footer__icon--phone" src="./assets/pwa/home/atoms-icons-24-ico-phone.png" alt="Icono teléfono" />{{
					'footerR-telephone' | translate
				}}</span
			>
		</article>
	</ng-container>
</footer>

<imv-ui-modal #modalStartRequest [isMobile]="isMobile" type="email" (closeModal)="onCloseStartRequestModal()"
	class="modal-align-title">
	<div slot="title">
		<h2 id="modalStartRequestTitle">Iniciar solicitud</h2>
	</div>
	<div slot="content__email">
		<form [formGroup]="formStartRequest">
			<p class="imv-modal__title">
				{{ 'modal-start-text' | translate }}
			</p>
			<div class="control__wrap">
				<imv-form-control [showErrors]="submittedStartRequest" name="province" id="province"
					formControlName="province" variant="imv-input" type="select" [options]="options ? options : []"
					label="{{ 'modal-start-label' | translate }}" variant=" modal-home">
				</imv-form-control>
			</div>
		</form>
		<div *ngIf="isDelegatedProvince === 'VASQUE'">
			<p id="delegated-province-message">{{ 'delegated-province-message' | translate }}</p>
			<p>
				<a id="delegated-province-link" title="{{ 'delegated-province-link' | translate }}"
					href="{{ 'delegated-province-href' | translate }}">{{
					'delegated-province-link' | translate
					}}</a>
			</p>
			<p id="delegated-vasque-province-message">{{ 'delegated-vasque-province-message' | translate }}</p>
			<p>
				<a id="delegated-vasque-province-link" title="{{ 'delegated-vasque-province-link' | translate }}"
					href="{{ 'delegated-vasque-province-href' | translate }}">{{
					'delegated-vasque-province-link' | translate
					}}</a>
			</p>
		</div>
		<div *ngIf="isDelegatedProvince === 'NAVARRA'">
			<p id="delegated-navarra-province-message">
				{{ 'delegated-navarra-province-message' | translate }}
				<a id="delegated-navarra-province-link" title="{{ 'delegated-navarra-province-link' | translate }}"
					href="{{ 'delegated-navarra-province-href' | translate }}">{{
					'delegated-navarra-province-link' | translate
					}}</a>
			</p>
		</div>
	</div>
	<div slot="actions">
		<imv-ui-button *ngIf="!isDelegatedProvince" eid="modal-start-button" theme="primary"
			[variant]="isMobile? '' : 'mdc-button mdc-button--modal'" (click)="onSendProvince()" [fullWidth]="isMobile"
			class="mobile-full-width">
			<span slot="text">{{ 'modal-start-button' | translate }}</span>
		</imv-ui-button>
	</div>
</imv-ui-modal>

<imv-ui-modal #modalStartProceedings [isMobile]="isMobile" type="email" (closeModal)="onCloseStartProceedings()"
	class="modal-align-title">
	<div slot="title">
		<h2 id="modalStartProceedingsTitle">Gestionar solicitud</h2>
	</div>
	<div slot="content__email">
		<form [formGroup]="formStartProceedings">
			<p class="imv-modal__title-proceedings">
				{{ 'modal-proceedings-start-text' | translate }}
			</p>
			<div class="control__wrap">
				<imv-form-control [showErrors]="submittedStartProceedings" name="province" id="province-proceedings"
					formControlName="province" variant="imv-input" type="select" [options]="options ? options : []"
					label="{{ 'modal-start-label' | translate }}" variant=" modal-home">
				</imv-form-control>
			</div>
		</form>
		<br />
		<div *ngIf="isDelegatedProvinceProceedings === 'VASQUE'">
			<p id="delegated-province-message-proceedings">{{ 'delegated-province-message' | translate }}</p>
			<p>
				<a id="delegated-province-link-proceedings"
					title="{{ 'delegated-province-link-proceedings' | translate }}"
					href="{{ 'delegated-province-href' | translate }}">{{
					'delegated-province-link' | translate
					}}</a>
			</p>
			<p id="delegated-vasque-province-message-proceedings">{{ 'delegated-vasque-province-message' | translate }}
			</p>
			<p>
				<a id="delegated-vasque-province-link-proceedings"
					title="{{ 'delegated-vasque-province-link' | translate }}"
					href="{{ 'delegated-vasque-province-href' | translate }}">{{
					'delegated-vasque-province-link' | translate
					}}</a>
			</p>
		</div>
		<div *ngIf="isDelegatedProvinceProceedings === 'NAVARRA'">
			<p id="delegated-navarra-province-message-proceedings">
				{{ 'delegated-navarra-province-message' | translate }}
				<a id="delegated-navarra-province-link-proceedings"
					title="{{ 'delegated-navarra-province-link' | translate }}"
					href="{{ 'delegated-navarra-province-href' | translate }}">{{ 'delegated-navarra-province-link' |
					translate }}</a>
			</p>
		</div>
	</div>
	<div slot="actions" class="mt-0">
		<imv-ui-button *ngIf="!isDelegatedProvinceProceedings" eid="modal-start-button-proceedings" theme="primary"
			[fullWidth]="isMobile" [variant]="isMobile? '': 'mdc-button mdc-button--modal'"
			(click)="onSendProvinceStartProceedings()" class="mobile-full-width">
			<span slot="text">{{ 'modal-start-button' | translate }}</span>
		</imv-ui-button>
	</div>
</imv-ui-modal>

<imv-ui-modal #modalStartVariations [isMobile]="isMobile" type="email" (closeModal)="onCloseStartVariations()">
	<h2 slot="title" id="modalStartVariationsTitle">Comunicar variaciones en tu IMV</h2>
	<div slot="content__email">
		<form [formGroup]="formStartVariations">
			<p class="imv-modal__title-variations">
				{{ 'modal-variations-start-text' | translate }}
			</p>
			<div class="control__wrap">
				<imv-form-control
					[showErrors]="submittedStartVariations"
					name="province"
					id="province-variations"
					formControlName="province"
					variant="imv-input"
					type="select"
					[options]="options ? options : []"
					label="{{ 'modal-start-label' | translate }}"
					variant=" modal-home"
				>
				</imv-form-control>
			</div>
		</form>
		<br />
		<div *ngIf="isDelegatedProvinceVariations === 'VASQUE'">
			<p id="delegated-province-message-variations">{{ 'delegated-province-message' | translate }}</p>
			<p>
				<a id="delegated-province-link-variations" title="{{ 'delegated-province-link' | translate }}" href="{{ 'delegated-province-href' | translate }}">{{
					'delegated-province-link' | translate
				}}</a>
			</p>
			<p id="delegated-vasque-province-message-variations">{{ 'delegated-vasque-province-message' | translate }}</p>
			<p>
				<a id="delegated-vasque-province-link-variations" title="{{ 'delegated-vasque-province-link' | translate }}" href="{{ 'delegated-vasque-province-href' | translate }}">{{
					'delegated-vasque-province-link' | translate
				}}</a>
			</p>
		</div>
		<div *ngIf="isDelegatedProvinceVariations === 'NAVARRA'">
			<p id="delegated-navarra-province-message-variations">
				{{ 'delegated-navarra-province-message' | translate }}
				<a id="delegated-navarra-province-link-variations" title="{{ 'delegated-navarra-province-link' | translate }}" href="{{ 'delegated-navarra-province-href' | translate }}">{{
					'delegated-navarra-province-link' | translate
				}}</a>
			</p>
		</div>
	</div>
	<div slot="actions">
		<imv-ui-button
			*ngIf="!isDelegatedProvinceVariations"
			eid="modal-start-button-variations"
			theme="primary"
			variant=" mdc-button mdc-button--modal"
			(click)="onSendProvinceStartVariations()"
		>
			<span slot="text">{{ 'modal-start-button' | translate }}</span>
		</imv-ui-button>
	</div>
</imv-ui-modal>

<imv-ui-modal #modalAccessDraft [isMobile]="isMobile" type="email" (closeModal)="onCloseAccessDraftModal()">
	<h2 slot="title" id="modalAccessDraftTitle">{{ 'modal-draft-title' | translate }}</h2>
	<div slot="content__email">
		<form [formGroup]="formAccessDraft">
			<p class="imv-modal__title" id="access-draft-modal-title">{{ 'modal-draft-subtitle' | translate }}</p>
			<ul>
				<li>{{ 'modal-draft-access-text' | translate }}</li>
			</ul>
			<imv-form-control
				[upperCase]="false"
				name="accessDraftToken"
				id="accessDraftToken"
				formControlName="accessDraftToken"
				variant="imv-input imv-input--full"
				type="text"
				label="{{ 'modal-access-label' | translate }}"
				[maxLength]="8"
				[showErrors]="formAccessDraft.dirty"
			>
			</imv-form-control>
		</form>
		<br />
		<div class="imv-info__text--center">
			<a id="resend-email-link" *ngIf="appConfig.caiss === 0" href="javascript:void(0)" (click)="onReferenceRequest()" class="imv-info__text imv-info__text--center">{{
				'resend' | translate
			}}</a
			><br />
		</div>
	</div>
	<div slot="actions">
		<imv-ui-button [disabled]="formAccessDraft.invalid" eid="access-draft-modal-button" theme="primary" variant=" mdc-button mdc-button--modal" (click)="onSendAccessDraftToken()">
			<span slot="text">{{ 'modal-start-button' | translate }}</span>
		</imv-ui-button>
	</div>
</imv-ui-modal>

<imv-ui-modal #modalAccessProceedings [isMobile]="isMobile" type="email" (closeModal)="onCloseAccessProceedingsModal()">
	<h2 slot="title" id="modalAccessProceedingsTitle">{{ 'modal-access-title' | translate }}</h2>
	<div slot="content__email">
		<form [formGroup]="formAccessProceedings">
			<p class="imv-modal__title">{{ 'modal-access-subtitle' | translate }}</p>
			<ul>
				<li>{{ 'modal-draft-access-text' | translate }}</li>
			</ul>
			<imv-form-control
				[upperCase]="false"
				name="accessProceedingsToken"
				id="accessProceedingsToken"
				formControlName="accessProceedingsToken"
				variant="imv-input imv-input--full"
				type="text"
				label="{{ 'modal-access-label' | translate }}"
				[maxLength]="8"
				[showErrors]="formAccessProceedings.dirty"
			>
			</imv-form-control>
		</form>
		<br />
		<div class="imv-info__text--center">
			<a id="resend-email-link-proceedings" *ngIf="appConfig.caiss === 0" href="javascript:void(0)" (click)="onReferenceConsult()" class="imv-info__text imv-info__text--center">{{
				'resend' | translate
			}}</a
			><br />
		</div>
	</div>
	<div slot="actions">
		<imv-ui-button
			[disabled]="formAccessProceedings.invalid"
			eid="access-proceedings-modal-button"
			theme="primary"
			variant=" mdc-button mdc-button--modal"
			(click)="onSendAccessProceedingsToken()"
		>
			<span slot="text">{{ 'modal-start-button' | translate }}</span>
		</imv-ui-button>
	</div>
</imv-ui-modal>
