<imv-ui-modal #modal [isMobile]="isMobile" type="fail" class="modal-aign-butons modal-align-title">
	<imv-ui-button id="dialog-control-close" eid="dialog-control-close" theme="secondary"
		variant=" mdc-button--secondary mdc-button--modal" (click)="close()">
		<span slot="text">{{ action }}</span>
	</imv-ui-button>
	<div slot="title">
		<h1 id="dialog-control-title">{{ title }}</h1>
	</div>
	<p slot="text" id="dialog-control-description">{{ description }}</p>
	<ul slot="text">
		<li slot="text" class="dialog-trace" *ngFor="let trace of traces">{{ trace.mensaje }}</li>
	</ul>
	<div slot="actions">
		<form [formGroup]="frm" (submit)="doSubmit()">
			<imv-form-control id="dialog-control" [showErrors]="submitted" [upperCase]="false" formControlName="ctrl"
				[required]="true" variant="imv-input imv-input--full" type="text" label="{{ label }}"
				optionalLabel="{{ '' }}" [maxLength]="size">
			</imv-form-control>
		</form>
		<imv-ui-button id="dialog-control-accept" eid="dialog-control-accept" theme="primary"
			[variant]="isMobile ? '' : 'mdc-button mdc-button--modal'" [fullWidth]="isMobile" [disabled]="frm.invalid"
			(click)="doSubmit()">
			<span slot="text">{{ button }}</span>
		</imv-ui-button>
	</div>
</imv-ui-modal>
