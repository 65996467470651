const FEATURE_TOGGLES: any = {};
const features = [
	{
		name: 'VASQUE_PROVINCE_DELEGATION',
		description: 'Muestra la delegación de las provincias vascas',
		value: true,
	},
	{
		name: 'NAVARRA_PROVINCE_DELEGATION',
		description: 'Muestra la delegación de la provincia de navarra',
		value: false,
	},
	{
		name: 'DATE_VALIDATIONS',
		description: 'Carga las validaciones de fechas',
		value: true,
	},
	{
		name: 'DANA_AFFECTED_MUNICIPIES',
		description: 'Muestra las preguntas PF81 y PF84 si fueron afectadas por la Dana',
		value: true, // False by default because is temporary
	},
	{
		name: 'FIRST_QUARTER_VALIDATION',
		description: 'Validación del primer cuatrimestre del año que muestra las preguntas PF81 y PF84',
		value: true, // True by default
	},
];
console.table(features);

features.forEach(ft => {
	FEATURE_TOGGLES[ft.name] = ft.value;
});

export { FEATURE_TOGGLES };
