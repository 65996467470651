<imv-ui-modal #modal [isMobile]="isMobile" type="success" class="modal-align-title">
	<div slot="title">
		<h1 id="dialog-success-title">{{ title }}</h1>
	</div>
	<p slot="text" id="dialog-success-content">{{ description }}</p>
	<div slot="actions">
		<imv-ui-button eid="dialog-success-button" (click)="close()" theme="primary" [fullWidth]="isMobile"
			class="mobile-full-width">
			<span slot="text">{{ action }}</span>
		</imv-ui-button>
	</div>
</imv-ui-modal>
